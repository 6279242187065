import i18n from "@/i18n/i18n"
import { Layout, layoutViews } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Config"
import { BaseTemplateConfig } from "piramis-base-components/src/plugins/AdminTemplate/NewConfig"
import TokenId from "piramis-base-components/src/shared/utils/TokenId"
import { actionTypeBreadcrumb, baseBoardBreadcrumbs, baseChatBreadcrumbs, baseNetworkBreadcrumbs } from "../AppConfigBreadcrumbsHelper"
import TemplateContext from "piramis-base-components/src/plugins/AdminTemplate/includes/TemplateContext";
import { ModuleManagerState } from "../logic/Modules/ModulesManager"
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types"

export const routes: BaseTemplateConfig['routes'] = [
  {
    path: '',
    component: layoutViews["Panel"],
    children: [
      {
        name: 'NetworkDashboard',
        path: '/networks/:networkId/dashboard',
        component: () => import("@/views/networks/network-dashboard.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => baseNetworkBreadcrumbs(ctx.route),
        },
      },
      {
        name: 'NetworkTriggers',
        path: '/networks/:networkId/triggers',
        component: () => import("@/views/networks/network-triggers.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => baseNetworkBreadcrumbs(
              ctx.route,
              [ { label: i18n.t('networktriggers_page_title').toString() } ]
          ),
        }
      },
      {
        name: 'NetworkTrigger',
        path: '/networks/:networkId/triggers/:triggerId?/:actionType',
        component: () => import("@/views/networks/network-trigger.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => baseNetworkBreadcrumbs(
              ctx.route,
              [ { label: i18n.t('networktriggers_page_title').toString(), routeName: 'NetworkTriggers' },
                ...ctx.route.params.triggerId ? [ { label: TokenId.getId(ctx.route.params.triggerId.toString()) } ] : [],
                { label: actionTypeBreadcrumb(ctx) }
              ]
          ),
        }
      },
      {
        name: 'NetworksAdd',
        path: '/networks/add',
        component: () => import("@/views/networks/networks-add.vue"),
      },
      {
        name: 'NetworkModulesCatalog',
        path: '/networks/:networkId/modules-catalog',
        component: () => import("@/views/modules/modules-catalog.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => baseNetworkBreadcrumbs(
              ctx.route,
              [ { label: i18n.t('networkmodulescatalog_page_title').toString() } ]
          )
        }
      },
      {
        name: 'NetworkActiveModules',
        path: '/networks/:networkId/active-modules',
        component: () => import("@/views/modules/active-modules.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => baseNetworkBreadcrumbs(
              ctx.route,
              [ { label: i18n.t('networkactivemodules_page_title').toString() } ]
          )
        }
      },
      {
        name: 'NetworkCurrentModule',
        path: `/networks/:networkId/module/:moduleGuidOrType`,
        component: () => import("@/includes/logic/Modules/components/CurrentModule.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => baseNetworkBreadcrumbs(ctx.route,
              [ { label: i18n.t('menu_active_modules_title').toString() },
                ModuleManagerState && ModuleManagerState.currentModule
                    ? { label: i18n.t(ModuleManagerState.currentModule.model.titleKey).toString() }
                    : {} ]
          )
        }
      },
      {
        name: 'chats',
        path: '/',
        component: () => import("@/views/chats.vue"),
      },
      {
        name: 'hidden_chats',
        path: '/hidden',
        component: () => import("@/views/hidden-chats.vue"),
      },
      {
        name: 'NetworksList',
        path: '/networks',
        component: () => import("@/views/networks/networks-list.vue"),
      },
      {
        name: 'boards',
        path: '/boards',
        component: () => import("@/views/posts-planner/views/boards.vue"),
      },
      {
        name: 'posts_planner',
        path: `/board/:${ [ EntityTypes.BOT_ID ] }/posts-planner`,
        component: () => import("@/views/posts-planner/views/posts-planner.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => [
            ...baseBoardBreadcrumbs(ctx.route),
            { label: i18n.t('menu_posts_planner_title') },
          ]
        }
      },
      {
        name: 'board_styles',
        path: `/board/:${ EntityTypes.BOT_ID }/styles`,
        component: () => import("@/views/posts-planner/views/board-styles-list.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => [
            ...baseBoardBreadcrumbs(ctx.route),
            { label: i18n.t('menu_board_styles_title') },
          ]
        }
      },
      {
        name: 'style',
        path: `/board/:${ EntityTypes.BOT_ID }/styles/:actionType`,
        component: () => import("@/views/posts-planner/views/style-page.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => {
            return [
              ...baseBoardBreadcrumbs(ctx.route),
              { label: i18n.t('menu_board_styles_title') },
              ...ctx.route.query.styleId ? [ { label: ctx.route.query.styleId.toString().split('.')[0] } ] : [],
              { label: actionTypeBreadcrumb(ctx) }
            ]
          }
        }
      },
      {
        name: 'post',
        path: `/board/:${ EntityTypes.BOT_ID }/post/:actionType`,
        component: () => import("@/views/posts-planner/views/post.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => {
            return [
              ...baseBoardBreadcrumbs(ctx.route),
              { label: i18n.t('menu_posts_planner_title') },
              { label: actionTypeBreadcrumb(ctx) }
            ]
          }
        }
      },
      {
        name: 'drafts',
        path: `/board/:${ EntityTypes.BOT_ID }/drafts/`,
        component: () => import("@/views/posts-planner/views/drafts-list.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => [
            ...baseBoardBreadcrumbs(ctx.route),
            { label: i18n.t('menu_drafts_title') },
          ]
        }
      },
      {
        name: 'buy',
        path: `/chat/:${ EntityTypes.CHAT_ID }/buy/`,
        component: () => import('@/views/buy-chat-license.vue'),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('nav_buy_title') },
          ]
        },
      },
      {
        name: 'Community_dashboard',
        path: `/chat/:${ EntityTypes.CHAT_ID }/`,
        component: () => import("@/views/community-dashboard.vue"),
        meta: {
          breadcrumbs: () => {
            return [
              ...baseChatBreadcrumbs(),
              { label: i18n.t('menu_dashboard_title') }
            ]
          }
        }
      },
      {
        name: 'ActiveModules',
        path: `/chat/:${ EntityTypes.CHAT_ID }/active-modules`,
        component: () => import("@/views/modules/active-modules.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_active_modules_title') },
          ]
        }
      },
      {
        name: 'ModulesCatalog',
        path: `/chat/:${ EntityTypes.CHAT_ID }/modules-catalog/:tab?`,
        component: () => import("@/views/modules/modules-catalog.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_active_modules_title'), routeName: 'ActiveModules' },
            { label: i18n.t('modulescatalog_page_title') },
          ]
        }
      },
      {
        name: 'CurrentModule',
        path: `/chat/:${ EntityTypes.CHAT_ID }/module/:moduleGuidOrType`,
        component: () => import("@/includes/logic/Modules/components/CurrentModule.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_active_modules_title') },
            ModuleManagerState && ModuleManagerState.currentModule
                ? { label: i18n.t(ModuleManagerState.currentModule.model.titleKey) }
                : {}
          ]
        }
      },
      // todo: уже все на модулях. видимо, уже не нужен
      {
        name: 'migrate_chat_to_modules',
        path: `/chat/:${ EntityTypes.CHAT_ID }/migrate`,
        component: () => import("@/views/chat/migrate-chat-to-modules-landing.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('modules_migrate_preview_card_title') },
          ]
        }
      },
      {
        name: 'chat_common_settings',
        path: `/chat/:${ EntityTypes.CHAT_ID }/settings/:tab?`,
        component: () => import("@/views/chat-common-settings.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('nav_bar_settings_tab_title') },
            { label: i18n.t('menu_chat_common_settings_title') },
          ]
        }
      },
      {
        name: 'users_rights',
        path: `/chat/:${ EntityTypes.CHAT_ID }/users-rights`,
        component: () => import("@/views/chat/users-rights.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('nav_bar_settings_tab_title') },
            { label: i18n.t('menu_users_rights_title') }
          ]
        },
      },
      {
        name: 'chat_triggers',
        path: `/chat/:${ EntityTypes.CHAT_ID }/triggers`,
        component: () => import("@/views/chat/triggers.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('nav_bar_settings_tab_title') },
            { label: i18n.t('menu_chat_triggers_title') },
          ]
        }
      },
      {
        name: 'questionnaire',
        path: `/chat/:${ EntityTypes.CHAT_ID }/questionnaire/`,
        component: () => import("@/views/chat/questionnaire/questionnaire.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('nav_bar_settings_tab_title') },
            { label: i18n.t('menu_questionnaire_title') }
          ]
        }
      },
      {
        name: 'Achievements',
        path: `/chat/:${ EntityTypes.CHAT_ID }/achievements/`,
        component: () => import("@/views/achievements.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('nav_bar_settings_tab_title') },
            { label: i18n.t('menu_achievements_title') }
          ]
        }
      },
      {
        name: 'Branding',
        path: `/chat/:${ EntityTypes.CHAT_ID }/branding`,
        component: () => import("@/views/chat/branding.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('nav_bar_settings_tab_title') },
            { label: i18n.t('menu_branding_title') },
          ]
        }
      },
      {
        name: 'chat_trigger',
        path: `/chat/:${ EntityTypes.CHAT_ID }/triggers/:triggerId?/:actionType`,
        component: () => import("@/views/chat/trigger.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => {
            return [
              ...baseChatBreadcrumbs(),
              { label: i18n.t('menu_chat_triggers_title'), routeName: 'chat_triggers' },
              ...ctx.route.params.triggerId ? [ { label: TokenId.getId(ctx.route.params.triggerId.toString()) } ] : [],
              { label: actionTypeBreadcrumb(ctx) }
            ]
          }
        }
      },
      {
        name: 'posts_planner',
        path: `/board/${ EntityTypes.BOT_ID }/posts-planner`,
        component: () => import("@/views/posts-planner/views/posts-planner.vue"),
      },
      {
        name: 'questionnaire_form',
        path: `/chat/:${ EntityTypes.CHAT_ID }/questionnaire/form/:actionType/`,
        component: () => import("@/views/chat/questionnaire/questionnaire-form.vue"),
        meta: {
          breadcrumbs: (ctx: TemplateContext) => {
            return [
              ...baseChatBreadcrumbs(),
              { label: i18n.t('menu_questionnaire_title'), routeName: 'questionnaire' },
              ...ctx.route.query.formGuid && ctx.route.params.actionType !== 'copy' ? [ { label: ctx.route.query.formGuid.toString().split('.')[0] } ] : [],
              { label: actionTypeBreadcrumb(ctx) }
            ]
          }
        }
      },
      {
        name: 'invite_links_list',
        path: `chat/:${ EntityTypes.CHAT_ID }/link-list`,
        component: () => import("@/views/chat/invite-links/link-list.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_invite_links_list_title') }
          ]
        }
      },
      {
        name: 'invite_links_statistics',
        path: `chat/:${ EntityTypes.CHAT_ID }/link-statistics`,
        component: () => import("@/views/chat/invite-links/link-statistics.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            {
              label: i18n.t('menu_invite_links_list_title'),
              routeName: 'invite_links_list'
            },
            { label: i18n.t('menu_invite_links_statistics_title') }
          ]
        }
      },
      {
        name: 'statistics',
        path: `chat/:${ EntityTypes.CHAT_ID }/statistics`,
        component: () => import("@/views/chat/statistics/statistics.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_tab_chat_statistics_title'), },
            { label: i18n.t('menu_statistics_title'), },
          ]
        }
      },
      {
        name: 'statistics_users',
        path: `chat/:${ EntityTypes.CHAT_ID }/statistics/users`,
        component: () => import("@/views/chat/statistics/statistics-users.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_tab_chat_statistics_title'), },
            { label: i18n.t('menu_statistics_users_title'), },
          ]
        }
      },
      {
        name: 'statistics_users_analysis',
        path: `chat/:${ EntityTypes.CHAT_ID }/statistics/users-analysis`,
        component: () => import("@/views/chat/statistics/statistics-users-analysis-new.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_tab_chat_statistics_title'), },
            { label: i18n.t('menu_statistics_users_analysis_title'), },
          ]
        }
      },
      {
        name: 'statistics_referrals',
        path: `chat/:${ EntityTypes.CHAT_ID }/statistics/referrals`,
        component: () => import("@/views/chat/statistics/statistics-referrals.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_tab_chat_statistics_title'), },
            { label: i18n.t('menu_statistics_referrals_title'), },
          ]
        }
      },
      {
        name: 'reputation_chart',
        path: `chat/:${ EntityTypes.CHAT_ID }/statistics/reputation`,
        component: () => import("@/views/chat/reputation/reputation-chart.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_tab_chat_statistics_title'), },
            { label: i18n.t('menu_reputation_chart_title'), },
          ]
        },
      },
      {
        name: 'Journal_log',
        path: `chat/:${ EntityTypes.CHAT_ID }/journal/log`,
        component: () => import("@/views/chat/journal/journal-log.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_journal_log_title'), },
          ]
        }
      },
      {
        name: 'extra',
        path: `chat/:${ EntityTypes.CHAT_ID }/extra`,
        component: () => import("@/views/chat/extra.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_extra_title'), },
          ]
        }
      },
      {
        name: 'user_info',
        path: `/chat/:${ EntityTypes.CHAT_ID }/user-info/:userId`,
        component: () => import("@/views/chat/user-info.vue"),
      },
      {
        name: 'credentials',
        path: `/chat/:${ EntityTypes.CHAT_ID }/credentials/`,
        component: () => import("@/views/chat-credentials.vue"),
      },
      {
        name: 'statistic_topics',
        path: `chat/:${ EntityTypes.CHAT_ID }/statistics/topics`,
        component: () => import("@/views/chat/statistics/statistics-topics.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_tab_chat_statistics_title'), },
            { label: i18n.t('menu_statistic_topics_title'), },
          ]
        },
      },
      {
        name: 'statistic_reactions',
        path: `chat/:${ EntityTypes.CHAT_ID }/statistics/reactions`,
        component: () => import("@/views/chat/statistics/statistics-reactions.vue"),
        meta: {
          breadcrumbs: () => [
            ...baseChatBreadcrumbs(),
            { label: i18n.t('menu_tab_chat_statistics_title'), },
            { label: i18n.t('menu_statistic_reactions_title'), },
          ]
        },
      },
    ]
  },
  {
    path: '',
    component: layoutViews[Layout.Fullscreen],
    children: [
      {
        name: 'Form',
        path: "/form/:key/",
        component: () => import("@/views/form/form.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        name: 'Payment',
        path: "/payment/:type/:key/",
        component: () => import("@/views/payment/payment.vue"),
      },
      {
        name: 'Choose_chat',
        path: "/buy-license/choose-chat/",
        component: () => import("@/views/buy-license/choose-chat.vue"),
      },
      {
        name: 'start_add_chat',
        path: "/add-chat/:key?",
        component: () => import("@/views/add-chat.vue"),
      }
    ]
  }
]
