import {
  AddAdminByLinkRequest,
  AddAdminByLinkResponse, UsersResultResponse,
  AdminState,
  BindState,
  FullAddAdminRequest
} from "@/includes/types/AddAdmin/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

export class AddAdminApi {
  static async addAdminByName(type:'tg', body: FullAddAdminRequest) {
    return PostApi.sendRequest<UsersResultResponse>(type, 'addadminbyname', body)
  }

  static async deleteAdmin(type: 'tg', body: FullAddAdminRequest) {
    return PostApi.sendRequest<UsersResultResponse>(type, 'deleteadmin', body)
  }

  static async addAdminByLink(type: 'tg', body: AddAdminByLinkRequest) {
    return PostApi.sendRequest<AddAdminByLinkResponse>(type, 'addadminbylink', body)
  }

  static async stateAdminByCode(type: 'tg', { token }: AdminState) {
    return PostApi.sendRequest<BindState>(type, 'stateadminbycode', { token })
  }
}
