import { BasePunishActionModel, PunishActionTypesEnum } from "../../../PunishActions";

import { instanceToInstance, instanceToPlain, Transform, TransformationType, TransformFnParams } from "class-transformer";

function transformFn({ type, value }: TransformFnParams) {
  if (type === TransformationType.PLAIN_TO_CLASS) {
    if (Array.isArray(value)) {
      return value.map(a => BasePunishActionModel.fromObject(a))
    } else {
      return []
    }
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    return instanceToPlain(value);
  } else if (type === TransformationType.CLASS_TO_CLASS) {
    return instanceToInstance(value);
  }
}

export class IncrementalAction extends BasePunishActionModel {
  type = PunishActionTypesEnum.Incremental

  @Transform(transformFn) //https://github.com/typestack/class-transformer/issues/212#issuecomment-451066261
  actions!: Array<BasePunishActionModel> | null
}

