import BaseModule from "@/includes/logic/Modules/modules/BaseModule";
import { CreateTypeEnum, ModuleData, ModuleTypesEnum } from "@/includes/logic/Modules/types";
import store from "@/store/store";
import { ModulesTypes } from "@/includes/logic/Modules/ModulesTypes";
import { errorNotification } from "@/includes/NotificationService";
import ModuleBuildersFactory from "@/includes/logic/Modules/modules/ModuleBuildersFactory";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import { setNetworkModuleConfig } from "@/includes/logic/Networks/logic";
import { Modules, WeightModules } from "./modules/Modules";
import ModulesApi from "@/includes/Api/Modules.api";
import FilterModule from "@/includes/logic/Modules/modules/filters/FilterModule";

import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer'
import { isEqual } from "lodash";
import * as Sentry from "@sentry/browser";

type ModuleErrorItem = { type: BaseModule['type'], errors: BaseModule['errors'], guid: string, title: string }

export let ModuleManagerState: _ModulesManager | null = null

export const setModules = (modulesObj: ModuleData, Constructor: ClassConstructor<_ModulesManager>) => {
  if (ModuleManagerState && ModuleManagerState instanceof Constructor) {
    ModuleManagerState.setModules(modulesObj)
  } else {
    ModuleManagerState = new Constructor(modulesObj)
  }
}

//**************************************************************************************

export abstract class _ModulesManager {

  abstract blacklistedTypes: Array<ModuleTypesEnum>
  abstract removeModule(module: ModuleBuilder, data?: Record<string, any>): Promise<Array<BaseModule> | undefined>
  abstract saveModule(module: ModuleBuilder, data?: Record<string, any>): Promise<Array<BaseModule> | undefined>
  abstract getActiveModules(): Array<BaseModule>

  updatedModuleGuid: string | null = null

  currentModule: ModuleBuilder | null = null
  currentModuleType: ModuleTypesEnum | null = null

  modules!: Array<BaseModule>
  allModules: WeightModules = this.runtimeConfigModules()

  errors: Array<ModuleErrorItem> | null = null

  constructor(modulesObj: ModuleData) {
    this.setModules(modulesObj)
  }

  runtimeConfigModules() {
    const runtimeConfig = store.state.AdminTemplate?.runtime_config

    if (runtimeConfig) {
      const { default_modules_config } = runtimeConfig
      const browserLang = window.navigator.language
      const configLang = browserLang.includes('ru') ? 'ru' : 'en'

      return plainToInstance(WeightModules, { modules: default_modules_config[configLang] })
    }

    return plainToInstance(WeightModules, { modules: [] })
  }

  setModules(modulesObj: ModuleData) {
    const { modules } = plainToInstance(Modules, modulesObj)

    this.modules = modules

    this.setModulesErrors()
  }

  private setModulesErrors() {
    if (this.modules.length) {
      this.errors = this.modules.reduce<Array<ModuleErrorItem>>((acc, module) => {
        if (module.errors && module.errors.length) {
            acc.push({
              type: module.type,
                errors: module.errors,
                guid: module.guid,
              title: module.titleKey,
            })
        }

        return acc
      }, [])
    }
  }

  protected getSortedActiveModules(createType: Array<CreateTypeEnum>) {
    return this.modules
        .filter(m => !this.blacklistedTypes.includes(m.type))
        .filter(m => createType.includes(m.config.create_type))
        .sort((a, b) => {
          const itemWeight = (item: BaseModule) => this.allModules.modules.find((m) => m.type === item.type)?.weight ?? 0

          return itemWeight(b) - itemWeight(a)
        })
  }

  makeBuildersListReducer(acc: Array<ModuleBuilder>, module: BaseModule) {
    const builder = ModuleBuildersFactory.getBuilder(module.type)

    if (builder && builder.isVisible()) {
      builder.setBuilderModel(module)

      acc.push(builder)
    }

    return acc
  }

  allModuleBuilders() {
    return this.allModules.modules
        .filter(m => !this.blacklistedTypes.includes(m.type))
        .slice()
        .sort((a, b) => b.weight - a.weight)
        .reduce(this.makeBuildersListReducer, [])
  }

  totalModules() {
    if (this.blacklistedTypes.length) {
      return this.allModules.modules.filter(m => !this.blacklistedTypes.includes(m.type)).length
    }

    return this.allModules.modules.length
  }

  setCurrentModule(moduleData: BaseModule) {
    const builder = ModuleBuildersFactory.getBuilder(moduleData.type)
    if (builder) {
      builder.setBuilderModel(moduleData)

      this.currentModule = builder
      this.currentModuleType = moduleData.type
    }
  }

  clearCurrentModule() {
    this.currentModule = null
  }

  getModuleByCriteria<T extends keyof Pick<BaseModule, 'guid' | 'type'>>(criteria: T, value: BaseModule[T]) {
    return this.modules.find(m => m[criteria] === value)
  }

  get isCurrentModuleChanged() {
    if (this.currentModule) {
      return !isEqual(instanceToPlain(this.currentModule.model), instanceToPlain(this.currentModule.modelBackup))
    }

    return false
  }

  getDefaultModule(chatId: number, type: ModuleTypesEnum) {
    return ModulesApi.getDefaultModuleConfig(chatId, type)
        .then(({ data }) => {
            const ClassInfo = ModulesTypes.find(t => t.name === type)

            if (ClassInfo) {
              return plainToInstance(ClassInfo.value, data.module)
            } else {
              const message = `Unknown module type, got: ${ type }`

              errorNotification(message)
            }
        })
        .catch(errorNotification)
  }

  get isCurrentModuleSaved() {
    if (this.currentModule) {
      return !!this.getModuleByCriteria('guid', this.currentModule.model.guid)
    }

    return false
  }

  prepareModule(type: ModuleTypesEnum, chatId: number) {
    const module = this.getModuleByCriteria('type', type)

    if (module) {
      this.setCurrentModule(module)
    } else {
      this.getDefaultModule(chatId, type)
          .then((model) => {
            if (model) {
              this.setCurrentModule(model)
            }
          })
    }
  }
}

export class ChatModulesManager extends _ModulesManager {
  blacklistedTypes: Array<ModuleTypesEnum> = [
      ModuleTypesEnum.NetworksModerateCommandHandlerModule
  ]

  getActiveModules() {
    return this.getSortedActiveModules([ CreateTypeEnum.Manual, CreateTypeEnum.Auto ])
  }

  async setModuleConfig (chatId: number, module: BaseModule) {
    if (module instanceof FilterModule && module.filter === null) {
      Sentry.captureException(new Error("'filter' FIELD IS EMPTY"), {
        extra: {
          chat_id: chatId,
          user_id: store.state?.AdminTemplate?.user ?? '-',
          module
        }
      });
    }

    return ModulesApi.setModuleConfig(chatId, module)
        .then(({ data }) => data)
        .catch(errorNotification)
  }

  async removeModule(module: ModuleBuilder) {
    module.model.config.create_type = CreateTypeEnum.Manual
    module.model.config.enabled = false

    const res = await this.setModuleConfig(store.getters.chatId, module.model);

    if (res) {
      await store.dispatch('updateChatState', res);

      this.setModules(res.chat.modules_config)

      return this.modules
    }
  }

  async saveModule(module: ModuleBuilder) {
    module.model.config.create_type = CreateTypeEnum.Manual

    const res = await this.setModuleConfig(store.getters.chatId, module.model);

    if (res) {
      await store.dispatch('updateChatState', res);

      this.setModules(res.chat.modules_config)
      module.setBuilderModel(module.model)

      return this.modules
    }
  }
}

export class NetworkModulesManager extends _ModulesManager {
  blacklistedTypes: Array<ModuleTypesEnum> = [
    ModuleTypesEnum.SendAdminMessageModule,
    ModuleTypesEnum.SendWakeupMessageModule,
    ModuleTypesEnum.InactiveKickHandlerModule,
    ModuleTypesEnum.FirstInactiveKickHandlerModule,
    ModuleTypesEnum.NewUsersHandlerModule,
    ModuleTypesEnum.NewUserDeprecatedTriggersHandlerModule,
    ModuleTypesEnum.NewUserTriggersHandlerModule,
    ModuleTypesEnum.CloseChatHandlerModule,
    ModuleTypesEnum.DailyReportModule,
    ModuleTypesEnum.ApiNotifyHandlerModule,
    ModuleTypesEnum.ReportCommandHandlerModule,
    ModuleTypesEnum.ReputationMessageHandlerModule,
    ModuleTypesEnum.MessageBindedChannelHandlerModule,
    ModuleTypesEnum.SummarizePostHandlerModule,
    ModuleTypesEnum.SummarizeMessageHandlerModule
  ]

  async removeModule(module: ModuleBuilder) {
    module.model.config.create_type = CreateTypeEnum.Network
    module.model.config.enabled = false

    if (store.state.networksState.currentNetwork) {
      const res = await setNetworkModuleConfig(store.state.networksState.currentNetwork.id, module.model);

      if (res) {
        return this.modules
      }
    }
  }

  async saveModule(module: ModuleBuilder) {
    if (store.state.networksState.currentNetwork) {
      module.model.config.create_type = CreateTypeEnum.Network

      const res = await setNetworkModuleConfig(store.state.networksState.currentNetwork.id, module.model);

      if (res) {
        return this.modules
      }
    }
  }

  getActiveModules(): Array<BaseModule> {
    return this.getSortedActiveModules([ CreateTypeEnum.Network ]);
  }
}
