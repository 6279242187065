import { ModuleTypesEnum } from "../types";

import ChatTopCommandHandlerModuleBuilder from "./modules/ChatTopCommandHandlerModule/ChatTopCommandHandlerModuleBuilder";
import ReportCommandHandlerModuleBuilder
  from "./modules/ReportCommandHandlerModule/ReportCommandHandlerModuleBuilder";
import ReferralModuleBuilder from "./modules/ReferralModule/ReferralModuleBuilder";
import AnchorsHandlerModuleBuilder from "./modules/AnchorsHandlerModule/AnchorsHandlerModuleBuilder";
import ApiNotifyHandlerModuleBuilder
  from "./modules/ApiNotifyHandlerModule/ApiNotifyHandlerModuleBuilder";

import CommandLimitHandlerModuleBuilder
  from "./modules/CommandLimitHandlerModule/CommandLimitHandlerModuleBuilder";
import ModerateCommandHandlerModuleBuilder
  from "./modules/ModerateCommandHandlerModule/ModerateCommandHandlerModuleBuilder";
import RulesCommandHandlerModuleBuilder
  from "./modules/RulesCommandHandlerModule/RulesCommandHandlerModuleBuilder";
import SendWakeupMessageModuleBuilder from "./modules/SendWakeupMessageModule/SendWakeupMessageModuleBuilder";
import SendAdminMessageModuleBuilder from "./modules/SendAdminMessageModule/SendAdminMessageModuleBuilder";
import EntertainmentCallHandlerModuleBuilder
  from "./modules/EntertainmentCallHandlerModule/EntertainmentCallHandlerModuleBuilder";
import InactiveKickHandlerModuleBuilder
  from "./modules/InactiveKickHandlerModule/InactiveKickHandlerModuleBuilder";
import FirstInactiveKickHandlerModuleBuilder
  from "./modules/FirstInactiveKickHandlerModule/FirstInactiveKickHandlerModuleBuilder";
import EntertainmentHandlerModuleBuilder
  from "./modules/EntertainmentHandlerModule/EntertainmentHandlerModuleBuilder";
import ChatCryptoCommandHandlerModuleBuilder
  from "./modules/ChatCryptoCommandHandlerModule/ChatCryptoCommandHandlerModuleBuilder";
import DailyReportModuleBuilder from "./modules/DailyReportModule/DailyReportModuleBuilder";
import AlbumLimitHandlerModuleBuilder from "./modules/AlbumLimitHandlerModule/AlbumLimitHandlerModuleBuilder";
import CloseChatHandlerModuleBuilder from "./modules/CloseChatHandlerModule/CloseChatHandlerModuleBuilder";
import AutocorrectionHandlerModuleBuilder
  from "./modules/AutocorrectionHandlerModule/AutocorrectionHandlerModuleBuilder";
import NewUsersAttackHandlerModuleBuilder
  from "./modules/NewUsersAttackHandlerModule/NewUsersAttackHandlerModuleBuilder";
import NsfwHandlerModuleBuilder from "./modules/NsfwHandlerModule/NsfwHandlerModuleBuilder";
import EditMediaHandlerModuleBuilder from "./modules/EditMediaHandlerModule/EditMediaHandlerModuleBuilder";
import FacecontrolHandlerModuleBuilder
  from "./modules/FacecontrolHandlerModule/FacecontrolHandlerModuleBuilder";
import MessageBindedChannelHandlerModuleBuilder
  from "./modules/MessageBindedChannelHandlerModule/MessageBindedChannelHandlerModuleBuilder";
import NewUsersLimitHandlerModuleBuilder
  from "./modules/NewUsersLimitHandlerModule/NewUsersLimitHandlerModuleBuilder";
import BotGuardHandlerModuleBuilder
  from "./modules/BotGuardHandlerModule/BotGuardHandlerModuleBuilder";
import EnterBindedChannelHandlerModuleBuilder
  from "./modules/EnterBindedChannelHandlerModule/EnterBindedChannelHandlerModuleBuilder";
import StopWordFilterHandlerModuleBuilder from "./modules/StopWordFilterHandlerModule/StopWordFilterHandlerModuleBuilder";
import StoryFilterHandlerModuleBuilder
  from "./modules/StoryFilterHandlerModule/StoryFilterHandlerModuleBuilder";
import VideoNoteFilterHandlerModuleBuilder
  from "./modules/VideoNoteFilterHandlerModule/VideoNoteFilterHandlerModuleBuilder";
import SpoilerMessageFilterHandlerModuleBuilder
  from "./modules/SpoilerMessageFilterHandlerModule/SpoilerMessageFilterHandlerModuleBuilder";
import ContactFilterHandlerModuleBuilder
  from "./modules/ContactFilterHandlerModule/ContactFilterHandlerModuleBuilder";
import DiceFilterHandlerModuleBuilder
  from "./modules/DiceFilterHandlerModule/DiceFilterHandlerModuleBuilder";
import AudioFilterHandlerModuleBuilder
  from "./modules/AudioFilterHandlerModule/AudioFilterHandlerModuleBuilder";
import HieroglyphMessageFilterHandlerModuleBuilder
  from "./modules/HieroglyphMessageFilterHandlerModule/HieroglyphMessageFilterHandlerModuleBuilder";
import RtlMessageFilterHandlerModuleBuilder
  from "./modules/RtlMessageFilterHandlerModule/RtlMessageFilterHandlerModuleBuilder";
import VoiceFilterHandlerModuleBuilder from "./modules/VoiceFilterHandlerModule/VoiceFilterHandlerModuleBuilder";
import AnimationFilterHandlerModuleBuilder
  from "./modules/AnimationFilterHandlerModule/AnimationFilterHandlerModuleBuilder";
import VideoFilterHandlerModuleBuilder from "./modules/VideoFilterHandlerModule/VideoFilterHandlerModuleBuilder";
import PhotoFilterHandlerModuleBuilder from "./modules/PhotoFilterHandlerModule/PhotoFilterHandlerModuleBuilder";
import DocumentFilterHandlerModuleBuilder
  from "./modules/DocumentFilterHandlerModule/DocumentFilterHandlerModuleBuilder";
import RegularExpressionFilterHandlerModuleBuilder
  from "./modules/RegularExpressionFilterHandlerModule/RegularExpressionFilterHandlerModuleBuilder";
import PermittedWordFilterHandlerModuleBuilder
  from "./modules/PermittedWordFilterHandlerModule/PermittedWordFilterHandlerModuleBuilder";
import CensureFilterHandlerModuleBuilder
  from "./modules/CensureFilterHandlerModule/CensureFilterHandlerModuleBuilder";
import CapsMessageFilterHandlerModuleBuilder
  from "./modules/CapsMessageFilterHandlerModule/CapsMessageFilterHandlerModuleBuilder";
import MessageLengthFilterHandlerModuleBuilder
  from "./modules/MessageLengthFilterHandlerModule/MessageLengthFilterHandlerModuleBuilder";
import AnyMessageFilterHandlerModuleBuilder
  from "./modules/AnyMessageFilterHandlerModule/AnyMessageFilterHandlerModuleBuilder";
import ChatReplyFilterHandlerModuleBuilder
  from "./modules/ChatReplyFilterHandlerModule/ChatReplyFilterHandlerModuleBuilder";
import ChannelMessageFilterHandlerModuleBuilder
  from "./modules/ChannelMessageFilterHandlerModule/ChannelMessageFilterHandlerModuleBuilder";
import ChannelReplyFilterHandlerModuleBuilder
  from "./modules/ChannelReplyFilterHandlerModule/ChannelReplyFilterHandlerModuleBuilder";
import BotReplyFilterHandlerModuleBuilder
  from "./modules/BotReplyFilterHandlerModule/BotReplyFilterHandlerModuleBuilder";
import StickerFilterHandlerModuleBuilder
  from "./modules/StickerFilterHandlerModule/StickerFilterHandlerModuleBuilder";
import FloodFilterHandlerModuleBuilder
  from "./modules/FloodFilterHandlerModule/FloodFilterHandlerModuleBuilder";
import BotCommandFilterHandlerModuleBuilder from "./modules/BotCommandFilterHandlerModule/BotCommandFilterHandlerModuleBuilder";
import StickerPackFilterHandlerModuleBuilder
  from "./modules/StickerPackFilterHandlerModule/StickerPackFilterHandlerModuleBuilder";
import EmailLinksFilterHandlerModuleBuilder
  from "./modules/EmailLinksFilterHandlerModule/EmailLinksFilterHandlerModuleBuilder";
import EmojiFilterHandlerModuleBuilder
  from "./modules/EmojiFilterHandlerModule/EmojiFilterHandlerModuleBuilder";
import ReferralFilterHandlerModuleBuilder
  from "./modules/ReferralFilterHandlerModule/ReferralFilterHandlerModuleBuilder";
import WebLinksFilterHandlerModuleBuilder
  from "./modules/WebLinksFilterHandlerModule/WebLinksFilterHandlerModuleBuilder";
import SelfLinksFilterHandlerModuleBuilder
  from "./modules/SelfLinksFilterHandlerModule/SelfLinksFilterHandlerModuleBuilder";
import ReputationMessageHandlerModuleBuilder
  from "./modules/ReputationMessageHandlerModule/ReputationMessageHandlerModuleBuilder";
import WarnCommandHandlerModuleBuilder
  from "./modules/WarnCommandHandlerModule/WarnCommandHandlerModuleBuilder";
import XpHandlerModuleBuilder from "./modules/XpHandlerModule/XpHandlerModuleBuilder";
import RanksHandlerModuleBuilder
  from "./modules/RanksHandlerModule/RanksHandlerModuleBuilder";
import LeaveUsersHandlerModuleBuilder
  from "./modules/LeaveUsersHandlerModule/LeaveUsersHandlerModuleBuilder";
import SilentAdminInviteHandlerModuleBuilder
  from "./modules/SilentAdminInviteHandlerModule/SilentAdminInviteHandlerModuleBuilder";
import SilentInviteHandlerModuleBuilder
  from "./modules/SilentInviteHandlerModule/SilentInviteHandlerModuleBuilder";
import NewUserTriggersHandlerModuleBuilder
  from "./modules/NewUserTriggersHandlerModule/NewUserTriggersHandlerModuleBuilder";
import NewUserDeprecatedTriggersHandlerModuleBuilder from "./modules/NewUserDeprecatedTriggersHandlerModule/NewUserDeprecatedTriggersHandlerModuleBuilder";
import NewUsersHandlerModuleBuilder
  from "./modules/NewUsersHandlerModule/NewUsersHandlerModuleBuilder";
import LocationFilterHandlerModuleBuilder
  from "./modules/LocationFilterHandlerModule/LocationFilterHandlerModuleBuilder";
import FirstCommentHandlerModuleBuilder from "./modules/FirstCommentHandlerModule/FirstCommentHandlerModuleBuilder";
import RestrictCommentHandlerModuleBuilder from "./modules/RestrictCommentHandlerModule/RestrictCommentHandlerModuleBuilder";
import NetworksModerateCommandHandlerModuleBuilder
  from "./modules/NetworksModerateCommandHandlerModule/NetworksModerateCommandHandlerModuleBuilder";
import VotebanHandlerModuleBuilder from "./modules/VotebanHandlerModule/VotebanHandlerModuleBuilder";
import SummarizeMessageHandlerModuleBuilder from "./modules/SummarizeMessageHandlerModule/SummarizeMessageHandlerModuleBuilder";
import SummarizePostHandlerModuleBuilder from "./modules/SummarizePostHandlerModule/SummarizePostHandlerModuleBuilder";
import SpamReactionHandlerModuleBuilder from "./modules/SpamReactionHandlerModule/SpamReactionHandlerModuleBuilder";
import ToxicFilterHandlerModuleBuilder from "./modules/ToxicFilterHandlerModule/ToxicFilterHandlerModuleBuilder";
import LanguageFilterHandlerModuleBuilder from "./modules/LanguageFilterHandlerModule/LanguageFilterHandlerModuleBuilder";
import KnowledgeBaseHandlerModuleBuilder from "./modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModuleBuilder";
import NewUsersSafeInviteLinksModuleBuilder from "./modules/NewUsersSafeInviteLinksModule/NewUsersSafeInviteLinksModuleBuilder";

export default class ModuleBuildersFactory {
  static getBuilder(type: ModuleTypesEnum) {
    switch (type) {
      case ModuleTypesEnum.ApiNotifyHandlerModule:
        return new ApiNotifyHandlerModuleBuilder()
      case ModuleTypesEnum.AnchorsHandlerModule:
        return new AnchorsHandlerModuleBuilder()
      case ModuleTypesEnum.ReferralModule:
        return new ReferralModuleBuilder()
      case ModuleTypesEnum.ChatTopCommandHandlerModule:
        return new ChatTopCommandHandlerModuleBuilder()
        //moderation
      case ModuleTypesEnum.ModerateCommandHandlerModule:
        return new ModerateCommandHandlerModuleBuilder()
      case ModuleTypesEnum.NetworksModerateCommandHandlerModule:
        return new NetworksModerateCommandHandlerModuleBuilder()
      case ModuleTypesEnum.ReportCommandHandlerModule:
        return new ReportCommandHandlerModuleBuilder()
      case ModuleTypesEnum.CommandLimitHandlerModule:
        return new CommandLimitHandlerModuleBuilder()
      case ModuleTypesEnum.RulesCommandHandlerModule:
        return new RulesCommandHandlerModuleBuilder()
        //activity
      case ModuleTypesEnum.SendWakeupMessageModule:
        return new SendWakeupMessageModuleBuilder()
      case ModuleTypesEnum.SendAdminMessageModule:
        return new SendAdminMessageModuleBuilder()
      case ModuleTypesEnum.EntertainmentHandlerModule:
        return new EntertainmentHandlerModuleBuilder()
      case ModuleTypesEnum.EntertainmentCallHandlerModule:
        return new EntertainmentCallHandlerModuleBuilder()
      case ModuleTypesEnum.ChatCryptoCommandHandlerModule:
        return new ChatCryptoCommandHandlerModuleBuilder()
      case ModuleTypesEnum.InactiveKickHandlerModule:
        return new InactiveKickHandlerModuleBuilder()
      case ModuleTypesEnum.FirstInactiveKickHandlerModule:
        return new FirstInactiveKickHandlerModuleBuilder()
      case ModuleTypesEnum.DailyReportModule:
        return new DailyReportModuleBuilder()
        /* moderation extra */
      case ModuleTypesEnum.AlbumLimitHandlerModule:
        return new AlbumLimitHandlerModuleBuilder()
      case ModuleTypesEnum.CloseChatHandlerModule:
        return new CloseChatHandlerModuleBuilder()
      case ModuleTypesEnum.AutocorrectionHandlerModule:
        return new AutocorrectionHandlerModuleBuilder()
      case ModuleTypesEnum.NewUsersAttackHandlerModule:
        return new NewUsersAttackHandlerModuleBuilder()
      case ModuleTypesEnum.NsfwHandlerModule:
        return new NsfwHandlerModuleBuilder()
      case ModuleTypesEnum.EditMediaHandlerModule:
        return new EditMediaHandlerModuleBuilder()
      case ModuleTypesEnum.FacecontrolHandlerModule:
        return new FacecontrolHandlerModuleBuilder()
      case ModuleTypesEnum.MessageBindedChannelHandlerModule:
        return new MessageBindedChannelHandlerModuleBuilder()
      case ModuleTypesEnum.NewUsersLimitHandlerModule:
        return new NewUsersLimitHandlerModuleBuilder()
      case ModuleTypesEnum.BotGuardHandlerModule:
        return new BotGuardHandlerModuleBuilder()
      case ModuleTypesEnum.EnterBindedChannelHandlerModule:
        return new EnterBindedChannelHandlerModuleBuilder()
        // filters
      case ModuleTypesEnum.StopWordFilterHandlerModule:
        return new StopWordFilterHandlerModuleBuilder()
      case ModuleTypesEnum.StoryFilterHandlerModule:
        return new StoryFilterHandlerModuleBuilder()
      case ModuleTypesEnum.VideoNoteFilterHandlerModule:
        return new VideoNoteFilterHandlerModuleBuilder()
      case ModuleTypesEnum.SpoilerMessageFilterHandlerModule:
        return new SpoilerMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ContactFilterHandlerModule:
        return new ContactFilterHandlerModuleBuilder()
      case ModuleTypesEnum.DiceFilterHandlerModule:
        return new DiceFilterHandlerModuleBuilder()
      case ModuleTypesEnum.AudioFilterHandlerModule:
        return new AudioFilterHandlerModuleBuilder()
      case ModuleTypesEnum.HieroglyphMessageFilterHandlerModule:
        return new HieroglyphMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.RtlMessageFilterHandlerModule:
        return new RtlMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.VoiceFilterHandlerModule:
        return new VoiceFilterHandlerModuleBuilder()
      case ModuleTypesEnum.AnimationFilterHandlerModule:
        return new AnimationFilterHandlerModuleBuilder()
      case ModuleTypesEnum.VideoFilterHandlerModule:
        return new VideoFilterHandlerModuleBuilder()
      case ModuleTypesEnum.PhotoFilterHandlerModule:
        return new PhotoFilterHandlerModuleBuilder()
      case ModuleTypesEnum.DocumentFilterHandlerModule:
        return new DocumentFilterHandlerModuleBuilder()
      case ModuleTypesEnum.RegularExpressionFilterHandlerModule:
        return new RegularExpressionFilterHandlerModuleBuilder()
      case ModuleTypesEnum.PermittedWordFilterHandlerModule:
        return new PermittedWordFilterHandlerModuleBuilder()
      case ModuleTypesEnum.CensureFilterHandlerModule:
        return new CensureFilterHandlerModuleBuilder()
      case ModuleTypesEnum.CapsMessageFilterHandlerModule:
        return new CapsMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.MessageLengthFilterHandlerModule:
        return new MessageLengthFilterHandlerModuleBuilder()
      case ModuleTypesEnum.AnyMessageFilterHandlerModule:
        return new AnyMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ChannelMessageFilterHandlerModule:
        return new ChannelMessageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ChatReplyFilterHandlerModule:
        return new ChatReplyFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ChannelReplyFilterHandlerModule:
        return new ChannelReplyFilterHandlerModuleBuilder()
      case ModuleTypesEnum.BotReplyFilterHandlerModule:
        return new BotReplyFilterHandlerModuleBuilder()
      case ModuleTypesEnum.StickerFilterHandlerModule:
        return new StickerFilterHandlerModuleBuilder()
      case ModuleTypesEnum.FloodFilterHandlerModule:
        return new FloodFilterHandlerModuleBuilder()
      case ModuleTypesEnum.BotCommandFilterHandlerModule:
        return new BotCommandFilterHandlerModuleBuilder()
      case ModuleTypesEnum.StickerPackFilterHandlerModule:
        return new StickerPackFilterHandlerModuleBuilder()
      case ModuleTypesEnum.EmailLinksFilterHandlerModule:
        return new EmailLinksFilterHandlerModuleBuilder()
      case ModuleTypesEnum.EmojiFilterHandlerModule:
        return new EmojiFilterHandlerModuleBuilder()
      case ModuleTypesEnum.ReferralFilterHandlerModule:
        return new ReferralFilterHandlerModuleBuilder()
      case ModuleTypesEnum.WebLinksFilterHandlerModule:
        return new WebLinksFilterHandlerModuleBuilder()
      case ModuleTypesEnum.SelfLinksFilterHandlerModule:
        return new SelfLinksFilterHandlerModuleBuilder()
      case ModuleTypesEnum.LocationFilterHandlerModule:
        return new LocationFilterHandlerModuleBuilder()
      case ModuleTypesEnum.WarnCommandHandlerModule:
        return new WarnCommandHandlerModuleBuilder()
        // reputation
      case ModuleTypesEnum.ReputationMessageHandlerModule:
        return new ReputationMessageHandlerModuleBuilder()
      case ModuleTypesEnum.XpHandlerModule:
        return new XpHandlerModuleBuilder()
      case ModuleTypesEnum.RanksHandlerModule:
        return new RanksHandlerModuleBuilder()
        //greeting
      case ModuleTypesEnum.LeaveUsersHandlerModule:
        return new LeaveUsersHandlerModuleBuilder()
      case ModuleTypesEnum.SilentInviteHandlerModule:
        return new SilentInviteHandlerModuleBuilder()
      case ModuleTypesEnum.SilentAdminInviteHandlerModule:
        return new SilentAdminInviteHandlerModuleBuilder()
      case ModuleTypesEnum.NewUserTriggersHandlerModule:
        return new NewUserTriggersHandlerModuleBuilder()
      case ModuleTypesEnum.NewUserDeprecatedTriggersHandlerModule:
        return new NewUserDeprecatedTriggersHandlerModuleBuilder()
      case ModuleTypesEnum.NewUsersHandlerModule:
        return new NewUsersHandlerModuleBuilder()
        //
      case ModuleTypesEnum.FirstCommentHandlerModule:
        return new FirstCommentHandlerModuleBuilder()
      case ModuleTypesEnum.RestrictCommentHandlerModule:
        return new RestrictCommentHandlerModuleBuilder()
      case ModuleTypesEnum.VotebanHandlerModule:
        return new VotebanHandlerModuleBuilder()
      case ModuleTypesEnum.SummarizeMessageHandlerModule:
        return new SummarizeMessageHandlerModuleBuilder()
      case ModuleTypesEnum.SummarizePostHandlerModule:
        return new SummarizePostHandlerModuleBuilder()
      case ModuleTypesEnum.SpamReactionHandlerModule:
        return new SpamReactionHandlerModuleBuilder()
      case ModuleTypesEnum.ToxicFilterHandlerModule:
        return new ToxicFilterHandlerModuleBuilder()
      case ModuleTypesEnum.LanguageFilterHandlerModule:
        return new LanguageFilterHandlerModuleBuilder()
      case ModuleTypesEnum.KnowledgeBaseHandlerModule:
        return new KnowledgeBaseHandlerModuleBuilder()
      case ModuleTypesEnum.NewUsersSafeInviteLinksModule:
        return new NewUsersSafeInviteLinksModuleBuilder()
    }
  }
}
