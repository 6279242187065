import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types";
import ModuleBuilder from "@/includes/logic/Modules/modules/ModuleBuilder"
import store from "@/store/store";
import { AI_EXPERIMENTAL_TAG, ULTIMATE_TAG } from "@/includes/constants";
import KnowledgeBaseHandlerModule from "@/includes/logic/Modules/modules/modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";

export default class KnowledgeBaseHandlerModuleBuilder extends ModuleBuilder {
  model!: KnowledgeBaseHandlerModule;

  view: ModuleBuilder['view'] = () => import('./KnowledgeBaseHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.AI

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'menu_book',
  }

  tags(): Array<TagData> | null {
    return AI_EXPERIMENTAL_TAG
  }

  tariffTags(): Array<TagData> | null {
    return store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG;
  }

}

