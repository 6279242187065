import i18n from "@/i18n/i18n";
import store from "@/store/store";
import { BaseTemplateConfig, MenuItemTypeEnum } from "piramis-base-components/src/plugins/AdminTemplate/NewConfig";
import { getTariffTranslationKey } from "../helpers/tariffHelper";
import Vue from "vue";
import { Guid } from "guid-typescript";
import { ADVANCED_TAG, BRAND_TAG, DEFAULT_BOARD_TOKEN, ULTIMATE_TAG } from "@/includes/constants";
import { getNetworkTitle } from "@/includes/logic/Networks/utils";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";
import router from "@/router/router";
import { Board } from "@/views/posts-planner/posts.types";

export const sidebar: BaseTemplateConfig['sidebar'] = [
  {
    type: MenuItemTypeEnum.Route,
    route: {
      name: 'chats',
    },
    icon: {
      name: 'icon-message-circle',
      iconType: "feather",
    },
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ (ctx) => store.getters.hasHiddenChats && store.getters.isChatSet || !!ctx && [ 'chats', 'hidden_chats' ].includes(ctx.route.name!.toString()) ],
    route: {
      name: 'hidden_chats',
    },
    icon: {
      name: 'icon-eye-off',
      iconType: 'feather'
    },
  },
  {
    type: MenuItemTypeEnum.Route,
    route: {
      name: 'NetworksList',
    },
    icon: {
      name: 'icon-database',
      iconType: "feather"
    }
  },
  {
    type: MenuItemTypeEnum.DynamicHeader,
    isVisible: [ (ctx) => !!ctx && !!ctx.route.params.networkId ],
    route: {
      name: 'NetworkDashboard'
    },
    img: '',
    title: (ctx) => {
      const network = store.state.networksState.currentNetwork

      return network ? getNetworkTitle(network) : ''
    },
    icon: {
      name: 'icon-database',
      iconType: "feather",
    },
  },
  {
    type: MenuItemTypeEnum.Divider,
    isVisible: [ (ctx) => !!ctx && !!ctx.route.params.networkId ],
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ () => store.getters.hasAtLeastOneAdvanced && !store.getters.isChatSet ],
    route: {
      name: 'boards',
    },
    icon: {
      name: 'icon-layout',
      iconType: "feather"
    }
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [
      (ctx) => !!ctx && !!ctx.route.params[EntityTypes.BOT_ID],
      () => store.getters.hasAtLeastOneAdvanced
    ],
    route: {
      name: 'posts_planner',
    },
    icon: {
      name: 'icon-calendar',
      iconType: "feather"
    },
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ (ctx) => !!ctx && !!ctx.route.params[EntityTypes.BOT_ID] ],
    route: {
      name: 'board_styles',
    },
    icon: {
      name: 'icon-layers',
      iconType: "feather"
    },
    tags: [
      () => {
        const boardId = router.app.$route.params[EntityTypes.BOT_ID]

        const currentBoard: Board | undefined = store.state.postsPlanner.boards?.find((board: Board) => board.board.token_id.toString() === boardId)

        if (currentBoard && (currentBoard as Board).board.token_code === DEFAULT_BOARD_TOKEN) {
          return BRAND_TAG[0]
        }

        return null
      }
    ]
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ (ctx) => !!ctx && !!ctx.route.params[EntityTypes.BOT_ID] ],
    route: {
      name: 'drafts',
    },
    icon: {
      name: 'icon-file-text',
      iconType: "feather"
    },
    tags: [
      () => {
        const boardId = router.app.$route.params[EntityTypes.BOT_ID]

        const currentBoard: Board | undefined = store.state.postsPlanner.boards?.find((board: Board) => board.board.token_id.toString() === boardId)

        if (currentBoard && (currentBoard as Board).board.token_code === DEFAULT_BOARD_TOKEN) {
          return BRAND_TAG[0]
        }

        return null
      }
    ]
  },
  {
    type: MenuItemTypeEnum.DynamicHeader,
    isVisible: [
      (ctx) => store.getters.isChatSet,
    ],
    route: {
      name: 'Community_dashboard'
    },
    title: (ctx) => (store.state.chatState.chat as any)?.group_title,
    img: (ctx) => (store.state.chatState.chat as any)?.group_photo,
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [
      (ctx) => store.getters.isChatSet,
    ],
    route: {
      name: 'buy',
    },
    icon: {
      name: 'icon-shopping-bag',
      iconType: "feather",
    },
    class: [ 'vu-nav-license' ],
  },
  {
    type: MenuItemTypeEnum.Divider
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [
      (ctx) => store.getters.isChatSet
    ],
    route: {
      name: 'Community_dashboard',
    },
    icon: {
      name: 'icon-monitor',
      iconType: "feather"
    },
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ () => store.getters.isChatSet ],
    route: {
      name: 'ActiveModules',
    },
    icon: {
      name: 'icon-life-buoy',
      iconType: "feather"
    },
  },
  {
    type: MenuItemTypeEnum.Dropdown,
    guid: Guid.create().toString(),
    isVisible: [
      (ctx) => store.getters.isChatSet
    ],
    icon: {
      name: 'icon-settings',
      iconType: "feather",
    },
    alias: 'menu_chat_settings_title',
    children: [
      {
        type: MenuItemTypeEnum.Route,
        route: {
          name: 'chat_common_settings',
        },
        icon: {
          name: 'icon-globe',
          iconType: "feather"
        },
      },
      {
        type: MenuItemTypeEnum.Route,
        route: {
          name: 'users_rights',
        },
        icon: {
          name: 'icon-user-check',
          iconType: "feather"
        },
      },
      {
        type: MenuItemTypeEnum.Route,
        route: {
          name: 'chat_triggers',
        },
        icon: {
          name: 'icon-zap',
          iconType: "feather"
        }
      },
      {
        type: MenuItemTypeEnum.Route,
        route: {
          name: 'questionnaire',
        },
        icon: {
          name: 'icon-clipboard',
          iconType: "feather"
        },
        tags: [
          () => {
            if (!(store.state.chatState.chat as any)?.limits?.options.includes('forms')) {
              return {
                text: i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('forms'))).toString(),
                color: store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff('forms')))).color
              }
            }

            return null
          }
        ]
      },
      {
        type: MenuItemTypeEnum.Route,
        route: {
          name: 'Achievements',
        },
        icon: {
          name: 'icon-star',
          iconType: "feather"
        },
        tags: [
          () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG[0]
        ]
      },
      {
        type: MenuItemTypeEnum.Route,
        route: {
          name: 'Branding',
        },
        icon: {
          name: 'icon-tag',
          iconType: "feather"
        },
        tags: [
          () => {
            if (!(store.state.chatState.chat as any)?.limits?.options.includes('branding')) {
              const optionTariff = Vue.prototype.$getOptionTariff('branding')

              return {
                text: i18n.t(getTariffTranslationKey(optionTariff)).toString(),
                color: store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(optionTariff))).color
              }
            }

            return null
          }
        ]
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [
          () => store.getters.isChatSet && store.state.chatState.chat!.version >= 6000
        ],
        route: {
          name: 'credentials',
        },
        icon: {
          name: 'icon-grid',
          iconType: "feather"
        },
      },
    ]
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ (ctx) => store.getters.isChatSet ],
    route: () => ({
      name: 'posts_planner',
      params: {
        [EntityTypes.BOT_ID]: store.state.chatState.chat?.post_board?.token_id?.toString() ?? '',
      },
    }),
    icon: {
      name: 'icon-calendar',
      iconType: "feather"
    },
    tags: [
      (args) => {
        if (!store.getters.isChatAtLeastAdvanced || !store.getters.isChatLicenseExists) {
          return ADVANCED_TAG[0]
        }

        return null
      }
    ]
  },
  {
    type: MenuItemTypeEnum.Dropdown,
    isVisible: [ (ctx) => store.getters.isChatSet ],
    guid: Guid.create().toString(),
    alias: 'menu_tab_invite_links_title',
    icon: {
      name: 'icon-link',
      iconType: "feather"
    },
    children: [
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [ (ctx) => store.getters.isChatSet ],
        route: {
          name: 'invite_links_list',
        },
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [ (ctx) => store.getters.isChatSet ],
        route: {
          name: 'invite_links_statistics',
        },
      },
    ]
  },
  {
    type: MenuItemTypeEnum.Dropdown,
    isVisible: [ (ctx) => store.getters.isChatSet ],
    guid: Guid.create().toString(),
    icon: {
      name: 'icon-bar-chart',
      iconType: "feather",
    },
    alias: 'menu_tab_chat_statistics_title',
    children: [
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [ (ctx) => store.getters.isChatSet ],
        route: {
          name: 'statistics',
        },
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [ (ctx) => store.getters.isChatSet ],
        route: {
          name: 'statistics_users',
        },
        tags: [
          () => {
            if (!(store.state.chatState.chat as any)?.limits?.options.includes('stat_users')) {
              const tariffOption = Vue.prototype.$getOptionTariff('stat_users')

              return {
                text: i18n.t(getTariffTranslationKey(tariffOption)).toString(),
                color: store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(tariffOption))).color
              }
            }

            return null
          }
        ],
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [ (ctx) => store.getters.isChatSet ],
        route: {
          name: 'statistics_users_analysis',
        },
        tags: [
          () => {
            if (!(store.state.chatState.chat as any)?.limits?.options.includes('statistic_users_analysis')) {
              const tariffOption = Vue.prototype.$getOptionTariff('statistic_users_analysis')

              return {
                text: i18n.t(getTariffTranslationKey(tariffOption)).toString(),
                color: store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(tariffOption))).color
              }
            }

            return null
          }
        ]
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [ (ctx) => store.getters.isChatSet ],
        route: {
          name: 'statistics_referrals',
        },
        tags: [
          () => {
            if (!(store.state.chatState.chat as any)?.limits?.options.includes('stat_referrals')) {
              const tariffOption = Vue.prototype.$getOptionTariff('stat_referrals')

              return {
                text: i18n.t(getTariffTranslationKey(tariffOption)).toString(),
                color: store.state.themeConfig.getTariff(i18n.t(getTariffTranslationKey(tariffOption))).color
              }
            }

            return null
          }
        ]
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [ (ctx) => store.getters.isChatSet ],
        route: {
          name: 'reputation_chart',
        }
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [
          (ctx) => store.getters.isChatSet,
        ],
        route: {
          name: 'statistic_reactions',
        }
      },
      {
        type: MenuItemTypeEnum.Route,
        isVisible: [
          false,
          (ctx) => store.getters.isChatSet,
        ],
        route: {
          name: 'statistic_topics',
        }
      },
    ]
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ (ctx) => store.getters.isChatSet ],
    route: {
      name: 'Journal_log',
    },
    icon: {
      name: 'icon-book',
      iconType: "feather"
    },
  },
  {
    type: MenuItemTypeEnum.Divider
  },
  {
    type: MenuItemTypeEnum.Route,
    isVisible: [ (ctx) => store.getters.isChatSet ],
    route: {
      name: 'extra',
    },
    icon: {
      name: 'icon-more-horizontal',
      iconType: "feather"
    },
  },
]
