import { navbar } from "@/includes/BaseTemplateConfig/navbar";
import { routes } from "@/includes/BaseTemplateConfig/routes";
import { sidebar } from "@/includes/BaseTemplateConfig/sidebar";

import store from "@/store/store";
import { deleteRecurringInvoice, getRecurringInvoicesReq } from "@/includes/logic/RecurringInvoices/logic";
import { BaseChatInfo } from "@/includes/types/Chat/types";
import state from "@/store/state";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { getNetworkConfig } from "@/includes/logic/Networks/logic";
import { AdminTemplateOptions, Layout } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Config";
import { ProductNames } from "piramis-base-components/src/plugins/AdminTemplate/components/OurProducts/types";
import { tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";
import { MenuItemType } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem";
import Vue from "vue";
import { hasMatchedRouteParams } from "piramis-base-components/src/components/SelectEntityWizard/includes/helpers";
import { isEmpty } from "lodash";

export const BaseTemplateConfig: AdminTemplateOptions['config'] = {
  feedbackItems: [
    {
      menuItem: {
        type: MenuItemType.DropDownLink,
        isVisible: [ true ],
        name: 'menu_help_youtube_title',
        icon: 'icon-youtube',
        featherIcon: true,
        url: 'https://www.youtube.com/@user-gz2go8dj9o',
      },
    },
    {
      menuItem: {
        isVisible: [ true ],
        type: MenuItemType.DropDownLink,
        name: 'menu_help_tech_support_title',
        icon: 'icon-life-buoy',
        featherIcon: true,
        url: () => {
          const locale = localStorage && localStorage.getItem('locale')

          if (locale) {
            if (locale === 'en') {
              return 'https://t.me/ChatKeeperBot_EN'
            }
          }

          return 'https://t.me/ChatKeeperBotSupport'
        },
      },
    },
    {
      menuItem: {
        isVisible: [ true ],
        type: MenuItemType.DropDownLink,
        name: 'menu_help_instruction_title',
        icon: 'icon-book',
        featherIcon: true,
        url: () => 'https://docs.chatkeeper.app',
      },
    },
    {
      menuItem: {
        isVisible: [ true ],
        type: MenuItemType.Component,
        name: 'menu_regexp_title',
        icon: 'icon-flag',
        featherIcon: true,
        component: () => import('piramis-base-components/src/plugins/AdminTemplate/components/regexp-validator/RegexpValidator.vue')
      },
    },
  ],
  navbar,
  routes,
  sidebar,
  globalGuards: {
    afterEach: (to) => {
      const chatIdParam = to.params[EntityTypes.CHAT_ID]
      const botIdParam = to.params[EntityTypes.BOT_ID]
      const networkIdParam = to.params.networkId

      if (!chatIdParam) {
        store.commit("updateChat", null);
        store.commit("resetFrontendTriggers");
        store.commit('clearChatStateFields')

        document.title = `${ process.env.VUE_APP_TARGET_PROJECT }.app`
      }

      if (store.state.chatState.chat) {
        const chat = store.state.chatState.chat

        document.title = `${ chat.group_title } (${ chat.chat_id })`
      }

      if (!botIdParam && store.state.postsPlanner.currentBoard) {
        store.commit('clear_current_board')
      }

      if (!networkIdParam && store.state.networksState.currentNetwork) {
        store.commit('CLEAR_CURRENT_NETWORK')
        store.commit('RESET_NETWORK_TRIGGERS')
      }

      if (to.name === 'boards' || botIdParam || !chatIdParam) {
        setTimeout(() => {
          Vue.prototype.$baseTemplate.saveButton.hide()
        }, 0)
      }
    },
    beforeEach: async (to, from, next) => {
      const chatData = store.state.chatState.chat;
      const chatIdParam = to.params[EntityTypes.CHAT_ID]
      const botIdParam = to.params[EntityTypes.BOT_ID]
      const networkIdParam = to.params.networkId

      if (chatIdParam) {
        if (Number.parseInt(chatIdParam) && !hasMatchedRouteParams(to)) {
          if (!chatData || chatData.chat_id !== parseInt(chatIdParam)) {
            store.commit("updateChat", null);
            store.commit("resetFrontendTriggers");
            store.commit('clearChatStateFields')

            await store.dispatch("requestChatConfig", { id: chatIdParam })

            if (ModuleManagerState && ModuleManagerState.currentModule && ModuleManagerState.currentModuleType) {
              ModuleManagerState.clearCurrentModule()
              ModuleManagerState.prepareModule(ModuleManagerState.currentModuleType, +chatIdParam)
            }
          }
        } else if (hasMatchedRouteParams(to)) {
          next()
        } else {
          next({ name: "chats" })
        }
      }

      if (networkIdParam) {
        if (!Number.isNaN(+networkIdParam)) {
          if (!store.state.networksState.currentNetwork) {

            const config = await getNetworkConfig(+networkIdParam)

            if (config) {
              await store.dispatch('setCurrentNetwork', config)
            } else {
              next({ name: "NetworksList" })
            }
          }
        } else {
          next({ name: "NetworksList" })
        }
      }

      if (to.path.includes('/boards') || botIdParam && !hasMatchedRouteParams(to)) {
        if (isEmpty(store.state.postsPlanner.boards)) {
          await store.dispatch("requestPostBoards")
        }

        if (botIdParam && !store.state.postsPlanner.currentBoard) {
          const board = await store.dispatch('setCurrentBoard', botIdParam)

          if (!board) {
            next({ name: 'boards' })
          }
        }
      }

      if (!networkIdParam && !botIdParam && !store.state.chatState.chatsInfo?.chats || to.name === 'chats') {
        await store.dispatch("requestChatsInfo", Vue)
      }

      next()
    },
  },
  footer: {
    version: (store.state as any).metaModule.PACKAGE_VERSION,
    ourProducts: {
      exclude: [
        ProductNames.CHATKEEPERBOT,
      ],
      settings: {
        [ProductNames.PRESSCODEBOT]: {
          link: `${ tgLoginLink('Presscodebot') }?start=cabinet_chatkeeper`
        }
      }
    }
  },
  meta: {
    projectName: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? 'Infibot.app' : 'ChatKeeper.app',
    apiUrl: 'api.chatkeeper.app/cabinet',
    projectDomain: 'https://chatkeeper.app',
    sidebarLogo: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? require('../../assets/images/infibot/logo/logo.png') : require('../../assets/images/logo/logo.png'),
    loginPageLogo: process.env.VUE_APP_TARGET_PROJECT === 'infibot' ? require('../../assets/images/infibot/logo/logo-full.png') : require('../../assets/images/logo/text-logo-dark.png'),
    tgResolvers: {
      supportBotDomain: 'ChatKeeperBotSupport',
      promotionBotDomain: 'ChatKeeperPromotionBot',
      mainBotDomain: 'ChatKeeperBot',
    },
    login: {
      // botAuthLink: 'https://t.me/ChatKeeperBot?start=cabinetlink',
      botAuthLink: 'https://t.me/ChatKeeperBot?start=cabinetlinkbeta',
      loginWidget: {
        botLogin: 'chatkeeperbot',
        tgWidgetAuthLink: 'u',
      }
    },
    languageCodes: [ 'ru', 'en' ],
    isSaveButtonVisible: false,
    paymentEntity: {
      target_type: (ctx) => {
        return {
          target_id: {
            chat_id: (store.state.chatState.chat as any)?.chat_id,
          },
          target_type: 'chat'
        }
      }
    },
  },
  profile: {
    advertiseButton: () => 'https://cabinet.chatkeeper.app/web/tgresolve/en/ChatKeeperPromotionBot%3Fstart%3Dloyalty_parnters',
    recurringInvoices: {
      getRecurringInvoicesReq: () => {
        return getRecurringInvoicesReq()
            .then(res => {
              if (res) {
                return res
              }

              return []
            })
      },
      deleteRecurringInvoiceReq: (invoice, vm) => {
        return deleteRecurringInvoice(invoice, vm)
            .then(res => {
              if (res) {
                return res
              }
            })
      },
    }
  },
  loyaltySystem: {
    partnerBlock: {
      partnerLink: (localeCode) => 'https://cabinet.chatkeeper.app/web/tgresolve/en/ChatKeeperPromotionBot%3Fstart%3Dloyalty_parnters'
    },
    companiesBlock: {
      termsOwnerLink: (localeCode) => 'https://cabinet.chatkeeper.app/web/tgresolve/en/ChatKeeperPromotionBot%3Fstart%3Dloyalty_parnters'
    }
  },
  companyRegistration: {
    powerOfAttorney: (localeCode): string => 'https://chatkeeper.app/wp-content/uploads/2022/08/power_of_attorney_ur_aug_2022.doc',
    howToRegisterUr: (localeCode): string => 'https://chatkeeper.app/wp-content/uploads/2022/08/how_to_register_ur-4.docx',
  },
  search: {
    entities: async (ctx) => {
      const chatsList = store.state.chatState.chatsInfo

      if (!chatsList) {
        await store.dispatch("requestChatsInfo")
      }

      return (store.getters.sortedVisibleChatsInfos as Array<BaseChatInfo>)
          .map((c) => ({ avatar: c.group_photo, title: c.group_title, id: c.chat_id }))
    },
    path: (ctx, router, selectedId) => {
      if (selectedId !== null) {
        const params = {
          [EntityTypes.CHAT_ID]: selectedId.toString()
        }

        if (!state.chatState.chat) {
          router.push({
            name: "Community_dashboard",
            params
          })
        } else {
          router.push({
            params
          })
        }
      }
    }
  },
  rightScreenButton: {
    link: (localeCode) => {
      if (localeCode === 'ru') return 'https://t.me/PressCodeSupportRu'
      else return 'https://t.me/PressCodeSupport'
    },
  },
  serviceData: {
    type: 'ServiceData',
    tariffs: {
      starter: {
        type: 'TariffInfo',
        img: require('../../assets/images/payments/tariffs/chatkeeper/starter.png'),
        color: 'rgb(177,69,250)',
        id: 1,
      },
      advanced: {
        type: 'TariffInfo',
        img: require('../../assets/images/payments/tariffs/chatkeeper/advanced.png'),
        color: 'rgb(177,69,250)',
        id: 2,
      },
      ultimate: {
        type: 'TariffInfo',
        img: require('../../assets/images/payments/tariffs/chatkeeper/ultimate.png'),
        color: 'rgb(177,69,250)',
        id: 3,
      },
    },
  },
  sidebarMenu: [],
}
