import './includes/polyfills/ResizeObserver'

import 'reflect-metadata';

import "core-js/stable";
import "regenerator-runtime/runtime";

import "@/assets/css/main.css";
import "./assets/scss/main.scss";
import 'ant-design-vue/dist/antd.less'

import 'piramis-base-components/src/assets/scss/main.scss'

import Vue, { PluginFunction } from "vue";
import { DynamicReactiveRefs, ReactiveRefs } from "vue-reactive-refs";
import VueScreen from "vue-screen";
import App from "./App.vue";
import i18n from "./i18n/i18n";
import router from "./router/router";
import store from "./store/store";
import isMobile from "@/assets/utils/isMobile";
import { BaseTemplateConfig } from "@/includes/BaseTemplateConfig";

import RouteHelpers from 'piramis-base-components/src/plugins/RouteHelpers/index'
import AdminTemplate from 'piramis-base-components/src/plugins/AdminTemplate'
import { AdminTemplateOptions } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Config";
import { ANT_LAYOUT_BREAKPOINTS } from "piramis-base-components/src/shared/constants";

import './directives'

import Antd from 'ant-design-vue'
import VueI18n from "vue-i18n";

Vue.use(RouteHelpers as unknown as PluginFunction<any>, { router })

Vue.use(Antd)

Vue.use(VueScreen, ANT_LAYOUT_BREAKPOINTS);

Vue.use(DynamicReactiveRefs);
Vue.use(ReactiveRefs);

const adminTemplateOptions: AdminTemplateOptions = {
  config: BaseTemplateConfig,
  router: router,
  store: store,
  i18n: i18n
}

Vue.use(AdminTemplate as unknown as PluginFunction<any>, adminTemplateOptions)

Vue.config.productionTip = false;

(window as any).mobilecheck = isMobile;

export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount("#app");
