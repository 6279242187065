import { AdminTemplateOptions } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Config";
import { MenuItemTypeEnum } from "piramis-base-components/src/plugins/AdminTemplate/NewConfig";

export const navbar: AdminTemplateOptions['config']['navbar'] = [
  {
    type: MenuItemTypeEnum.Component,
    isVisible: [ true ],
    component: () => import('piramis-base-components/src/plugins/AdminTemplate/components/feedback/FeedbackContainer.vue')
  }
]
